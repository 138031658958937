/// <reference path="../../../typings/jquery/jquery.d.ts"/>
/// <reference path="../../../typings/materialize-css/materialize-css.d.ts"/>

class Settings {
    private lv: LifeVine;
    private list;
    private settingsList;
    private settingForm;
    private buttons;
    private currentId;

    constructor(lv: LifeVine) {
        this.lv = lv;
        this.settingForm = jQuery('#form_setting');
        this.buttons = this.settingForm.find('button');
        this.settingsList = jQuery('#settings_list');

        this.settingsList.on('click', 'a', event => {
            event.preventDefault();
            event.stopPropagation();

            let id = $(event.target).closest('a').data('id');
            this.lv.settings().get(id)
                .done(setting => {
                    this.showSetting(setting);
                });
        });

        this.settingForm.on('submit', event => {
            event.preventDefault();
            event.stopPropagation();

            this.submitSetting();
        });

        this.settingForm.on('reset', event => {
            event.preventDefault();
            event.stopPropagation();

            this.clearForm();
        });

        this.refreshList();
        this.clearForm();
    }


    public refreshList() {
        this.lv.settings().get()
            .done(data => {
                this.list = data;
                this.renderList();
            });
    }

    private clearForm() {
        this.currentId = null;
        let data = {
            name: '',
            value: ''
        };

        this.buttons.prop('disabled', true);
        setFormData(this.settingForm, data);
        Materialize.updateTextFields();
    }

    private showSetting(setting) {
        this.currentId = setting.id;
        this.buttons.prop('disabled', false);
        setFormData(this.settingForm, setting);
        Materialize.updateTextFields();
    }

    private submitSetting() {
        if (this.currentId) {
            let formData = getFormData(this.settingForm);

            this.lv.settings().save(this.currentId, formData['value'])
                .done(data => {
                    if (data.success === true) {
                        Materialize.toast('Setting saved', 2000);
                        this.clearForm();
                        this.refreshList();
                    } else {
                        Materialize.toast('Setting could not be saved', 2000);
                        console.log(data.error);
                    }
                });
        }
    }

    private renderList() {
        this.settingsList.html('');
        this.list.forEach(setting => {
            this.settingsList.append(Settings.createListItem(setting));
        });
    }

    private static createListItem(setting) {
        let content = '' +
            `<span class="title">${setting.name}</span>` +
            `<a href="#!" class="secondary-content" data-id="${setting.id}"><i class="material-icons">edit</i></a>`;
        return jQuery('<li class="collection-item"></li>').append(content);
    }
}

window.app.page('page-settings', () => {
    return params => {
        if (window.controllers.settings) {
            window.controllers.settings.refreshList();
        } else {
            window.controllers.settings = new Settings(window.lifeVine);
        }
    };
});